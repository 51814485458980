import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useIonModal } from '@ionic/react'

import { logIn as logInApi } from '@supplyhound/api'
import styled from 'styled-components'

import { DESKTOP_WIDTH, HOME_ROUTE } from '@supplyhound/utils/config'
import { SUPPLIER_ID } from 'config'

import LoginSplash from '../../resources/loginsplash.png'
import Logo from '../../resources/logo.png'
import Spacer from '@supplyhound/components/common/Spacer'
import LoginForm, { LoginFormValues } from '@supplyhound/forms/LoginForm'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { useWindowDimensions } from '@supplyhound/hooks'
import { parse } from 'query-string'
import { LoginModal, LoginFlowStateType } from '@supplyhound/white-label-components'

const HEADER_HEIGHT = 72
const PAGE_MARGIN = 20

const OutterWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0px ${PAGE_MARGIN}px 0px ${PAGE_MARGIN}px;
  color: var(--ion-text-color);
`

const TopBar = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`

const LeftPanel = styled.div<{ isDesktop: boolean }>`
  margin: ${({ isDesktop }) => (isDesktop ? '0 20%' : '5% 0 5% 0')};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: calc(100% - ${HEADER_HEIGHT}px);
`

const SplashImage = styled.img`
  position: relative;
  right: 10%;
`

const RightPanel = styled.div<{ isDesktop: boolean }>`
  display: ${({ isDesktop }) => (isDesktop ? 'inline' : 'none')};
  margin: auto;
`

const HomePage: React.FC<{ isUserLoggedIn: boolean }> = ({ isUserLoggedIn }) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const { userProfileStore } = useStores()
  const { loadWhile } = useLoadingState()

  const { width } = useWindowDimensions()
  const isDesktop = width > DESKTOP_WIDTH

  const handleSubmitLoginForm = async ({ email, password }: LoginFormValues) => {
    try {
      const { data } = await loadWhile(() => logInApi({ email: email.trim(), password, supplier_id: SUPPLIER_ID }))
      const user = userProfileStore.decodedToken

      if (typeof user === 'object' && user?.role !== 'orderer') {
        setError('Invalid user.')
        return
      }

      // Set token and load profile
      userProfileStore.authStore.setToken(data.jwt)

      history.push(HOME_ROUTE)
      await userProfileStore.dispatchLoadProfile()
      await userProfileStore.identifyFullStoryUser()
    } catch (e) {
      setError('Incorrect email or password, please try again.')
    }
  }

  if (isUserLoggedIn) {
    history.push(HOME_ROUTE)
  }

  const { search } = useLocation()

  const { showModal, email, token: changePasswordToken } = parse(search)

  const [loginFlowState, setLoginFlowState] = useState<LoginFlowStateType>(
    showModal === 'true' ? 'enter_password' : 'reset_password'
  )
  const [present, dismiss] = useIonModal(LoginModal, {
    dismiss: () => dismiss(),
    loginFlowState,
    setLoginFlowState,
    email,
    token: changePasswordToken,
    supplierId: SUPPLIER_ID,
  })

  useEffect(() => {
    if (showModal) {
      present()
    }
  }, [showModal, email, changePasswordToken])

  return (
    <OutterWrapper>
      <div>
        <TopBar>
          <a href="https://www.beronio.com/">
            <img src={Logo} alt="Logo" style={{ width: '200px', marginTop: '30px' }} />
          </a>
        </TopBar>
        <LeftPanel isDesktop={isDesktop}>
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <h2>Sign in to Place an order</h2>
            <Spacer height={10} />
            <div>
              Online ordering for account holders only. If you do not have a billing account with Beronio Lumber{' '}
              <a href="https://www.beronio.com/contact-us/">click here</a> to get started.
            </div>
          </div>
          <LoginForm onSubmit={handleSubmitLoginForm} error={error} triggerResetPasswordModal={present} />
        </LeftPanel>
      </div>
      <RightPanel isDesktop={isDesktop}>
        <SplashImage src={LoginSplash} alt="splash" style={{}} />
      </RightPanel>
    </OutterWrapper>
  )
}

export default observer(HomePage)
