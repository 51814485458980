import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IonReactRouter } from '@ionic/react-router'
import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { autorun } from 'mobx'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

import { ClientApplication } from '@supplyhound/types'
import {
  HOME_ROUTE,
  SIGNUP_ROUTE,
  DESKTOP_WIDTH,
  JOB_SITE_TASK_DETAIL_ROUTE,
  JOB_SITE_LIST_DETAIL_ROUTE,
  JOB_SITE_LIST_SELECT_ROUTE,
  JOB_SITE_TASK_ADD_SUPPLIER_ROUTE,
  JOB_SITE_TASK_EDIT_ROUTE,
  JOB_SITE_ORDER_RECEIPT_ROUTE,
  HISTORY_ROUTE,
  HISTORY_JOBSITE_SELECT,
  HISTORY_ADD_ROUTE,
  HISTORY_ADD_TO_JOBSITE_ROUTE,
  ACCOUNT_ROUTE,
  MANAGE_JOB_SITES_SELECT_ROUTE,
  MANAGE_JOB_SITES_DETAIL_ROUTE,
} from '@supplyhound/utils/config'

import OrdererUnauthenticatedOnly from '@supplyhound/components/routes/OrdererUnauthenticatedOnly'
import OrdererAuthenticated from '@supplyhound/components/routes/OrdererAuthenticated'
import { Footer } from '@supplyhound/white-label-components'
import { WhiteLabelApp } from '@supplyhound/white-label-components'
import { SideBar } from '@supplyhound/white-label-components'
import { DesktopNav } from '@supplyhound/white-label-components'
import JobSiteListDetailPage from 'pages/JobSiteListDetailPage'
import JobSiteListSelectPage from 'pages/JobSiteListSelectPage'
import JobSiteTaskDetailPage from 'pages/JobSiteTaskDetailPage'
import JobSiteTaskSelectLocationPage from 'pages/JobSiteTaskSelectLocationPage'
import JobSiteTaskEditPage from 'pages/JobSiteTaskEditPage'
import OrderReceiptPage from 'pages/OrderReceiptPage'
import HistoryPage from 'pages/History/HistoryPage'
import HistoryJobSiteListSelectPage from 'pages/History/HistoryJobSiteListSelectPage'
import AccountPage from 'pages/Account'
import ManageJobSitesPage from 'pages/ManageJobSites/ManageJobSitesPage'
import ManageJobSitesDetails from 'pages/ManageJobSites/ManageJobSiteDetails'
import ItemHistoryPage from 'pages/ItemHistoryPage'

import SignUpPage from 'pages/SignUpPage'
import LoginPage from 'pages/LoginPage'

import 'styles/app.scss'

import metadata from '@supplyhound/utils/metadata'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'

import useWindowDimensions from '@supplyhound/hooks/useWindowDimensions'
import { ScreenConstraint } from '@supplyhound/layout'
import { LOGIN_ROUTE, SUPPLIER_LOCATIONS } from 'config'

import Logo from '../../resources/logo.png'

const App: React.FC = () => {
  const { userProfileStore } = useStores()
  const { loadWhile } = useLoadingState()
  const isUserLoggedIn = userProfileStore.authStore.isAuthenticated

  useEffect(() => {
    loadWhile(userProfileStore.dispatchLoadMarkets)
  }, [])

  useEffect(() => {
    if (isUserLoggedIn) {
      loadWhile(userProfileStore.dispatchLoadProfile)
    }
  }, [isUserLoggedIn])

  autorun(() => {
    if (isUserLoggedIn && userProfileStore.profile) {
      Sentry.setUser({ email: userProfileStore.profile?.email, name: userProfileStore.profile?.name })
    }
  })

  // Resolve mismatches between the current app version and the most
  // recently published with a hard reload.
  const handleReceivedClientApp = ({ name, version }: ClientApplication) => {
    if (name === metadata.appName && version !== metadata.appVersion) {
      console.warn(`Newer app version available: ${name}:${version}`)

      // Leave a few seconds to check the console.
      setTimeout(window.location.reload.bind(window.location), 5000)
    }
  }

  const { width } = useWindowDimensions()
  const isDesktop = width > DESKTOP_WIDTH

  return (
    <ActionCableConsumer channel="ClientAppsChannel" onReceived={handleReceivedClientApp}>
      <IonReactRouter>
        <Switch>
          <Route exact path={SIGNUP_ROUTE}>
            {isDesktop && <DesktopNav type={'orderer'} logo={Logo} homeLink={'https://www.beronio.com/'} />}
            <ScreenConstraint>
              <OrdererUnauthenticatedOnly>
                <SignUpPage />
              </OrdererUnauthenticatedOnly>
            </ScreenConstraint>
          </Route>

          <Route exact path={LOGIN_ROUTE}>
            <LoginPage isUserLoggedIn={isUserLoggedIn} />
          </Route>

          <Route path="/">
            {isDesktop && <DesktopNav type={'whitelabel'} logo={Logo} homeLink={'https://www.beronio.com/'} />}
            <ScreenConstraint>
              <WhiteLabelApp>
                <SideBar />
                <IonSplitPane contentId="main">
                  <IonRouterOutlet id="main">
                    <Switch>
                      <Route exact path={HOME_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteListSelectPage />
                        </OrdererAuthenticated>
                      </Route>

                      <Route exact path={JOB_SITE_LIST_SELECT_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteListSelectPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={JOB_SITE_LIST_DETAIL_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteListDetailPage />
                        </OrdererAuthenticated>
                      </Route>

                      <Route exact path={JOB_SITE_TASK_DETAIL_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteTaskDetailPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={JOB_SITE_TASK_ADD_SUPPLIER_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteTaskSelectLocationPage supplierLocations={SUPPLIER_LOCATIONS} />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={JOB_SITE_TASK_EDIT_ROUTE}>
                        <OrdererAuthenticated redirectPathName={LOGIN_ROUTE}>
                          <JobSiteTaskEditPage />
                        </OrdererAuthenticated>
                      </Route>

                      <Route exact path={JOB_SITE_ORDER_RECEIPT_ROUTE}>
                        <OrdererAuthenticated>
                          <OrderReceiptPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={HISTORY_ROUTE}>
                        <OrdererAuthenticated>
                          <HistoryPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={HISTORY_JOBSITE_SELECT}>
                        <OrdererAuthenticated>
                          <HistoryJobSiteListSelectPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={HISTORY_ADD_ROUTE}>
                        <OrdererAuthenticated>
                          <ItemHistoryPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={HISTORY_ADD_TO_JOBSITE_ROUTE}>
                        <OrdererAuthenticated>
                          <ItemHistoryPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={ACCOUNT_ROUTE}>
                        <OrdererAuthenticated>
                          <AccountPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={MANAGE_JOB_SITES_SELECT_ROUTE}>
                        <OrdererAuthenticated>
                          <ManageJobSitesPage />
                        </OrdererAuthenticated>
                      </Route>
                      <Route exact path={MANAGE_JOB_SITES_DETAIL_ROUTE}>
                        <OrdererAuthenticated>
                          <ManageJobSitesDetails />
                        </OrdererAuthenticated>
                      </Route>
                      {/* Catch all */}
                      <Route>
                        <Redirect to={LOGIN_ROUTE} />
                      </Route>
                    </Switch>
                  </IonRouterOutlet>
                </IonSplitPane>
              </WhiteLabelApp>
            </ScreenConstraint>
          </Route>
        </Switch>
        <Footer />
      </IonReactRouter>
    </ActionCableConsumer>
  )
}

export default observer(App)
