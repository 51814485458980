import React, { useState, useEffect } from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import { useParams } from 'react-router-dom'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { JobSiteDetailRouteParams } from '@supplyhound/types'
import { Header } from '@supplyhound/layout'
import { OrdererList } from '@supplyhound/white-label-components'
import NotificationBanner from '@supplyhound/components/NotificationBanner'
import { SUPPLIER_INFO } from 'config'

const JobSiteListDetailPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const [displayBanner, setDisplayBanner] = useState(false)

  const { jobSitesStore } = useStores()

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)

  const list = jobSitesStore.getJobSiteOrdererList(jobSiteId)
  const items = list?.active_items ? list.active_items : []
  const selectedItems = items.filter(({ selected }) => selected)

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  return (
    <IonPage>
      <Header
        label="Materials List"
        subLabel={(() => {
          let subLabel = ''
          subLabel =
            selectedItems.length > 0
              ? `${selectedItems.length}/${items.length} items selected for order`
              : 'Check items to order them'
          return subLabel
        })()}
      />
      {displayBanner && (
        <NotificationBanner
          onDidDismiss={() => setDisplayBanner(false)}
          message="These items have been added to your active materials list."
        />
      )}
      <OrdererList shortcuts={['Dimensional', 'Sheet', 'Fasteners']} supplierInfo={SUPPLIER_INFO} />
    </IonPage>
  )
}

export default observer(JobSiteListDetailPage)
