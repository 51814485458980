import React from 'react'
import ReactDOM from 'react-dom'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { LoadScript } from '@react-google-maps/api'
import { setupIonicReact } from '@ionic/react'
import mixpanel from 'mixpanel-browser'
import * as serviceWorker from './serviceWorker'
import { IonApp } from '@ionic/react'
import App from 'layout/App'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@supplyhound/themes'

import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import metadata from '@supplyhound/utils/metadata'

import * as FullStory from '@fullstory/browser'
import * as ActiveStorage from '@rails/activestorage'
import * as Sentry from '@sentry/react'

ActiveStorage.start()

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
  })
}

if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID })
}

if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
}

setupIonicReact({
  mode: 'ios',
})

const wsUrl = metadata.appName
  ? `${process.env.REACT_APP_API_WS_URL!}?app_name=${metadata.appName}`
  : process.env.REACT_APP_API_WS_URL!

ReactDOM.render(
  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={['places']}>
    <ActionCableProvider url={wsUrl}>
      <ThemeProvider theme={defaultTheme}>
        <IonApp>
          <App />
        </IonApp>
      </ThemeProvider>
    </ActionCableProvider>
  </LoadScript>,
  document.getElementById('root')
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// This is a comment to force deploy - 09/23/2022 #1
