export const SUPPLIER_ID = process.env.REACT_APP_SUPPLIER_ID
export const SUPPLIER_NAME = process.env.REACT_APP_SUPPLIER_NAME
export const LOGIN_ROUTE = '/login'

export const SUPPLIER_LOCATIONS = [
  {
    name: 'Beronio Lumber-San Francisco',
    address: 'Beronio Lumber, 2525 Marin St, San Francisco, CA 94124',
    displayText: 'Beronio - San Francisco',
    place_id: 'ChIJuyxT-6x_j4ARj1KA6HetiTI',
  },
]
export const SUPPLIER_INFO = {
  name: 'Beronio Lumber',
  number: '(415) 824-4300',
  logo: '',
}
